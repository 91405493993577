@import "../shared-components-styles/link-common.scss";

@mixin button($bgColor, $bgColorHover, $textColor, $textColorHover, $borderColorHover) {
  $self: &;
  @include font-size(18px, 26px);
  color: $textColor;
  text-align: center;
  padding: 7px 20px;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $bgColor;
  text-decoration: none;
  border: 4px solid $bgColor;
  transition: all 0.35s ease-in-out;
  outline: none;
  word-break: break-word;

  &:hover{
    background-color: $bgColorHover;
    color: $textColorHover;
    border-color: $bgColorHover;
  }

  &:focus {
    border-color: $borderColorHover;
  }

  &.link-type_external {
    @include external-link-light-theme;

    &:hover {
      @include external-link;
    }

    &:after {
      margin: 3px 0 0 10px !important;
    }
  }
}
