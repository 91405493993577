.todo{
	position: relative;
	overflow: hidden;
	border: 1px solid red;
	padding: 0px;


	&:before{
		content: attr(data-todo-text);
		float: right;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-color: red;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAOCAMAAABuIH5JAAAAMFBMVEX///////////////////////////////////////////////////////////////9Or7hAAAAAD3RSTlMAESIzRFVmd4iZqrvM3e5GKvWZAAAAuUlEQVR4AbWRy47FMAhDyePekEfw///tQF1VqWY1i/Gisg7GqIr8kzYoE2nhR3ZD1HycNJxKqKkubFWV6Tk1WJERYEUi+bZ2YCUWD6zYiZqY7RssmDtUkQr0Mzo9w41K8HVnGHLN7IySRsOHwN11ifvpd7T4lKC8ouWIbkzhWba6e6L93dq5OWDpAXnzB7aDI5oNK4sCSuClKz5T0vRLd5SnP4ZQD0DV5zW+QmUtNE1V8wVCjTCs/E0/qlsMpWWH2kkAAAAASUVORK5CYII=);
		position: relative;
		z-index: 101;
		min-height: 16px;
		color: #fff;
		padding-left: 46px;
		font-size: 14px;
		line-height: 14px;
	}

	&:after{
		display: block;
		content: " ";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba( 255, 0, 0, 0.3);
	}
}