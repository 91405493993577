@import 'footnote.scss';

@mixin bullet{
	width: 6px;
	height: 6px;
	background: $dark-blue;
	border-radius: 50%;
	overflow: hidden;
}

@mixin list-base {
	margin: 20px 0;

	li {
		padding: 0 0 0 30px;
		margin: 0;
		position: relative;
		list-style: none;
		
		@media print {
			padding: 0 0 0 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			@media print {
				content: none;
			}
		}
	}
}

@mixin bullet-list {
	@include list-base;
	@media print {
		list-style: disc;
	}

	li:before {
		@include bullet;
		top: 12px;
		left: 12px;
	}
}

@mixin ordered-list {
	@include list-base;

	li{
		counter-increment: my-ol-counter;

		&:before {
			content: counter(my-ol-counter) ".";
			font-weight: bold;
			line-height: inherit;
			color: $dark-blue;
			width: 25px;
			text-align: right;
		}
	}
}

.footnote-reference {
    display: none;
    cursor: pointer;

    .on-page-editing &,
    .on-editing & {
        @include edit-key-label; 
        position: relative; 
        margin-left: 30px;

        &:before {
            @include circle-number;
            content: '?';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -30px;
            margin: 0;
        }
    }

    &_enabled {
        @include circle-number;
        display: inline-block;
        margin-top: -2px;
    }
}