/* Edit panel styles */

.edit-panel {
	overflow-y: auto;
	width: 100%;
	padding: 10px 10px 0;

	@media (min-width: 48em){
		display: table;
		width: 100%;
	}

	&:after{
		content: " ";
		display: table;
		clear: both;
	}

	&__item{
		@media (min-width: 48em){
			float: left;
			padding-right: 10px;
		}
	}

	&:not(.edit-panel_multiline) &__item{
		@media (min-width: 48em){
			float: none;
			display: table-cell;
			vertical-align: top;
			width: 10px;

			&:last-child{
				padding-right: 0;
			}

			&_stretched{
				width: 100%;
			}
		}
	}
}

.edit-container{
	border: 1px dotted rgb(53, 203, 255);
	background: #fff;
	padding: 5px 10px 5px 45px;
	margin: 0 0 10px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAWCAMAAADpVnyHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjEyMUQ5QzAwNEEyNDExRTQ4MTA1RUY3MjJCMDdENEVGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjEyMUQ5QzAxNEEyNDExRTQ4MTA1RUY3MjJCMDdENEVGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTIxRDlCRkU0QTI0MTFFNDgxMDVFRjcyMkIwN0Q0RUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTIxRDlCRkY0QTI0MTFFNDgxMDVFRjcyMkIwN0Q0RUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5xpk9fAAABEVBMVEWVxTL////r9Njf7cGy1WnR5qf+/v3S5qiYxzj+//3n8tGdykLh7sT9/vr9/vyayDv8/fn6/PWVxTOayDzl8c3a67fq89bs9dvt9dyw1GbU56uWxTTQ5aP9/vvi78fz+Of7/ffi78ir0V31+u2ZxzqdyUGcyT/e7b/B3Yfq9NfO5J+fy0b7/fikzU6hzEmu02LT56no8tOcyUCq0Fqz1Wzl8cyjzU3h78bp89W923+izEyq0Vuw1Gfg7sSbyD3///70+eqt0mHJ4pfj8MnK4pmv02TV6K/r9NnU6Kyt0mDm8tDh78Xn8tDY6rTz+ejM45zT56uv02Xg7sPR5qbB3Yjo8tKz1m3V6K2kzU+eykTd7b4qIjUkAAAA00lEQVR42mJgoAGwE2VEAAErVXVeJ5gUO6OWCQsUhOkzGHMyMrpApQSjkM2wDXEUZOSDcuxlEBIc3hIClp68XFAuGytCxlmEnZNRQ1IPQ4pD0dQA6BQLIQZ0KQ4eEXagDFckA7oUB49fAEiGmwFdij9IgQ8oE6rGgCHlK6HtL8voI8wvjSElJqrLoCxm5OoujJDiDAZTXozmcprcDG46/EhS7JDw4mLiAbpASlAFIcUkb4bgRDgYCiF4Nrx84kxQIM4pEIgcokrhzHDgYc1AKwAQYADNUxJ9ep1jCQAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	background-position: 10px 10px;
	min-height: 34px;
	overflow: hidden;
	
	&:after{
		content: " ";
		display: table;
		clear: both;
	}

	&__heading{
		font-style: italic; 
		display: block;
		line-height: 24px;
		font-size: 18px;
		vertical-align: top;
		font-weight: 300;
		margin: 5px 0;
		
		@media (min-width: 48em){
			white-space: nowrap;
		}

		&:before {
			content: "<"
		}

		&:after {
			content: ">"
		}
	}
}

.edit-tags-list{
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: hidden;

	&__item{
		float: left;
		padding: 3px 5px;
		margin: 5px 0 5px 5px;
		position: relative;
		line-height: 16px;
		font-size: 14px;
		color: #000;
		cursor: default;
		border: 1px solid #aaaaaa;
		background: #f3f3f3;
		border-radius: 3px;
		vertical-align: top;
	}
}