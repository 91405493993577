@import "../shared-components-styles/content-page-styles.scss";

.article-page{ 
  &__intro{
    @include page-intro;
  }

  &__cta-buttons{
    max-width: $max-content-width;
    margin: $spacing-medium auto 32px;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    &-container{
      @include container;
      
      &:before{
        display: block;
        content: " ";
        width: 100%;
        background: $white;
        height: 2px;
      }
    }
  }

  &__cta-button{
    margin: 0 $spacing-medium $spacing-medium 0;
  }
}
