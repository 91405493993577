.footer {
    $self: &;
    color: $white;
    background-color: $primary-color;
    @include font-size(18px, 26px);
    padding-top: $spacing-medium;
    padding-bottom: $spacing-large;

    @include breakpoint(small) {
        padding-top: $spacing-xmedium;
        padding-bottom: $spacing-xmedium;
    }

    &__container {
        @include container;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $spacing-xxxlarge;

        @include breakpoint(small) {
            margin-bottom: $block-margin;
        }
    }
    &__logo-main{
        margin-top: 7px;
        @include breakpoint(small) {
            margin-top: 0;
        }
    }

    &__columns {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include breakpoint(small) {
            flex-direction: row;
        }
    }
    
    &__link {
        @include underlined-link('light');
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        margin-top: $block-margin;

        &-item {
            margin: 0 $spacing-large 10px 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
