$desktop-text-width: calc(50% - 5px);

.full-width-banner-block {
    $self: &;
    position: relative;
    overflow: hidden;
    @include color-overlay($media-overlay-color, 2);

    &__container {
        position: relative;
        @include container;
        max-width: 100%;
        @include full-size;
        height: 100%;
        max-width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @include breakpoint(small) {
            flex-direction: row;

            #{$self}_text-position_right & {
                justify-content: flex-start;
                padding-left: calc(50% + 10px);
            }
            #{$self}_text-position_left & {
                justify-content: flex-end;
                padding-right: calc(50% + 10px);
            }
        }
    }

    &__image {
        z-index: 1;
    }

    &__heading {
        margin: 0;
        @include font-size(32px, 40px);
        font-family: $font-family;
        overflow: hidden;
        padding: 0 0 3px;
        transition: all 0.5 ease-in-out;
        text-align: left;
        width : 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-overflow: ellipsis;

        @include breakpoint(small) {
            font-size: 2.5vw;
            line-height: 28px;
            -webkit-line-clamp: 3;
        }

        @include breakpoint(medium) {
            font-size: 2.2vw;
            line-height: 32px;
        }

        @include breakpoint(large) {
            @include font-size(32px, 40px);
        }
    }

    &__link {
        @include underlined-link;
        color: inherit;
    }

    &__intro {
        padding: 0;
        transition: all 0.5 ease-in-out;
        text-align: left;
        width : 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-overflow: ellipsis;

        #{$self}__heading + & {
            margin-top: $spacing-medium;
        }

        @include breakpoint(small) {
            -webkit-line-clamp: 3;
        }
    }

    &__text-content {
        position: relative;
        z-index: 3;
        background: $white;
        padding: calc($block-margin/2) $spacing-medium;
        word-break: break-word;
        overflow: hidden;
        align-self: center;
        transition: padding 1 ease-in-out;

        @include breakpoint(small) {
            max-width: $text-box-width;
            width: 100%;
            padding: 2vw;
            align-self: center;
        }

        @include breakpoint(large) {
            padding: $block-margin $block-margin;
        }
    }
}
