@import "../../scss/shared-components-styles/tabs-common.scss";

.tabs-panel {
    &__list {
        @include tab-list;
    }

    &__item {
        @include tab-item;
    }

    &__link {
        @include tab-link;
    }

    &__icon {
        @include tab-icon;
    }
}

.tab-content {
    display: none;

    &_active {
        display: block;
    }
}