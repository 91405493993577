$circle-size: 1.5rem;

@mixin circle-number {
    display: inline-block;
    vertical-align: middle;
    width: $circle-size;
    height: $circle-size;
    flex: 0 0 $circle-size;
    margin: 0 4px;
    @include font-size(12px, 24px);
    font-weight: 600;
    font-family: $font-family;
    text-align: center;
    border-radius: 50%;
    background-color: $blue;
    color: $white;
}

@mixin edit-key-label {
    display: inline;
    padding: 0 4px;
    margin: 0 2px;
    background-color: $light-yellow;
    border: 1px solid $gray;
    color: $black;
    font-family: $mono-font-family;
    @include font-size(14px, 18px);
}