.page-hero {
    $self: &;
    position: relative;
    margin: 0;

    &__heading {
        font-size: 40px;
        line-height: 48px;
        font-weight: 400;
        margin: 0;

        @include breakpoint(small) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            font-size: 48px;
            line-height: 56px;
        }
    }

    &__intro {
        #{$self}__heading + & {
            margin-top: $spacing-medium;
        }

        @include breakpoint(small) {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__text-container {
        display: flex;
        position: relative;
        @include container;

        @include breakpoint("extra-small-max", "max") {
            padding-left: 0;
            padding-right: 0;
        }

        @include breakpoint(small) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            min-height: 445px;
            padding-top: $spacing-xmedium;
            padding-bottom: $spacing-xmedium;
        }
    }

    &__text-content {
        position: relative;
        z-index: 2;
        background: $white;
        padding: calc($block-margin/2) $spacing-medium;

        @include breakpoint(small) {
            width: 50%;
            flex: 0 0 50%;
            max-width: $text-box-width;
            padding: $block-margin;
            align-self: center;
            margin-right: calc(50% + 10px);
        }
    }

    &__media-container {
        width: 100%;
        position: relative;
        z-index: 1;
        @include color-overlay($media-overlay-color, 4);

        @include breakpoint(small) {
            @include full-size;
        }
    }

    &__video {
        z-index: 1;
        &-container {
            @include breakpoint("extra-small-max", "max") {
                position: relative;
                @include fixed-proportion(375, 211);
            }
        }
    }

    &__image {
        @include breakpoint(small) {
            @include full-size;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }
}
