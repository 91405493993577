%bg-cover{
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

%bg-contain{
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

%border-box{
	-moz-box-sizing: border-box; 
	box-sizing: border-box; 
}

%clear{
	&:after{
		display: table;
		clear: both;
		overflow: hidden;
		content: " ";
		height: 0px;
		visibility: hidden;
	}
}

%absolute{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
}

%inherit{
	margin: 0;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

%inline-block { 
	display: -moz-inline-box;
	display: inline-block;
	zoom: 1; 
	*display: inline; 
	*vertical-align: auto; 
}