/*Image sizes*/

@mixin fixed-proportion-image($width, $height) {
	height: 0px;
	padding-bottom: calc(($height / $width) * 100%);
	position: relative;

	.image__img {
		@include object-fit(cover, center);
		position: absolute;
	}
}

@mixin fixed-proportion($width, $height) {
	padding-bottom: calc(($height / $width) * 100%);
}

@mixin fixed-height-image($width, $height) {
	height: ($height) + px;
	position: relative;
	background-position: center;
	background-size: cover;
	width: 100%;

	.image__img {
		@include object-fit(cover, center);
	}
}

@mixin fluid-image() {
	padding: 0px;
	@include full-size;
	background-position: center;
	background-size: cover;
	max-width: none;
	position: absolute !important;
    height: auto !important;

	.image__img {
		opacity: 0;
	}
}

@mixin original-proportions-image($width, $height) {
    padding: 0;
    height: auto;
	max-width: ($width)+px;

    .image__img{
        position: relative;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: ($height)+px;
        @include object-fit(null);
    }
}