@import "../../scss/shared-components-styles/tabs-common.scss";

.tabs-filter {
    &__tabs {
        @include tab-list;
    }

    &__tab {
        @include tab-item;
    }

    &__tab-link {
        @include tab-link;
    }

    &__tab-icon {
        @include tab-icon;
    }
}
