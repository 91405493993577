@import "../shared-components-styles/content-page-styles.scss";

.qa-list-page{ 
  &__intro{
    @include page-intro;
  }

  &__tabs-content {
    background-color: $white;
    padding: $spacing-xlarge 0 30px;

		@include breakpoint(small){
			padding: 75px 0 30px;
		}
  }

  &__tab-content {
    &:not(.tab-content) + & {
      margin-top: $spacing-xxxlarge;
    }
  }
}