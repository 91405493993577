$column-width-desktop: 25%;
$column-width-tablet: 50%;
$column-width-mobile: 100%;

.footer-column {
    padding-right: $spacing-xmedium;
    word-break: break-word;
    flex: 0 0 $column-width-mobile;
    width: $column-width-mobile;
    
    &:not(:last-child) {
        margin-bottom: $block-margin;
    }

    @include breakpoint(small) {
        flex: 0 0 $column-width-tablet;
        width: $column-width-tablet;
    }

    @include breakpoint(large) {
        flex: 0 0 $column-width-desktop;
        width: $column-width-desktop;

        &, &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    &__heading {
        display: block;
        margin: 0 0 $spacing-medium;
        font-weight: normal;
        font-family: $font-family;
        @include font-size(32px, 38px);
        color: $white;

        @include breakpoint(small) {
            margin: 0 0 calc($block-margin/2);
        }
    }
}
