$grid-horizontal-gap: 10px;

.topic-group-block {
	$self: &;
	padding: $spacing-xlarge 0 90px;
	
    &:nth-child(3n-2) {
        background-color: $white;
    }

    &:nth-child(3n-1) {
        background-color: $light-blue;
    }

    &:nth-child(3n) {
        background-color: $dark-blue;

        #{$self}__heading {
            color: $white;
        }
    }

	&__container {
		@include container;
	}

	&__category-label {
		@include visually-hidden;
	}

	&__item {
		flex: 0 0 100%;
		padding-right: $grid-horizontal-gap;

		@include breakpoint(small, "max") {
			&:not(:last-child) {
				margin-bottom: $spacing-xlarge;
			}
		}

		@include breakpoint(small) {
			flex: 1 1 50%;
		}

		&_full-width {
			flex: 0 0 100%;
			&:not(:last-child) {
				margin-bottom: $spacing-xlarge;
			}
		}
	}

	&__heading {
		margin: 0 0 $spacing-xlarge;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin-right: -#{$grid-horizontal-gap};

		&:not(:last-child) {
			margin-bottom: $spacing-xlarge;
		}
	}
}