@import '../../scss/shared-components-styles/rich-text-common.scss';
@import "../../scss/shared-components-styles/link-common.scss";
@import '../../scss/shared-components-styles/button.scss';

.rich-text {
  $self: &;
  color: $dark-blue;
  @include font-size(18px, 26px);
  font-family: $font-family-secondary;

  @include breakpoint(medium) {
    line-height: convert-to-rem(28px);
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    @include font-size(24px, 32px);
    font-weight: bold;
    font-family: $font-family-secondary;
    margin: 40px 0;

    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }
  

  img:not([class]){
    max-width: 100%;
    height: auto !important;
  }

  &:not(#{$self}_in-fact-block) {
    ul {
      @include bullet-list;
    }

    ol {
      @include ordered-list;
    }

    dl {
      margin: 20px 0;

      @include breakpoint(medium) {
        margin: 40px 0;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > *:first-child {
      margin-top: 0;
    }
  
    > *:last-child {
      margin-bottom: 0;
    }
  }

  a:not(#{$self}__footer-link-button) {
    @include underlined-link;
  }

  a.link-type_external {
    @include external-link;
  }

  a#{$self}__footer-link-button {
    @include button($blue, $medium-blue, $white, $dark-blue, $white);
    padding: 7px 18px;
    margin: 0 0 $spacing-small;

    @include breakpoint(small) {
      margin: 31px 0;
    }

    #{$self} > *:first-child & {
      margin-top: 0;
    }
  }

  & > *:last-child a#{$self}__footer-link-button{
    margin-bottom: 0;
  }

  &_in-footer {
    color: $white;
    font-family: $font-family;
    @include font-size(18px, 30px);

    &.on-editing {
      background-color: $primary-color;
    }

    p {
      margin-bottom: $spacing-medium;
    }

    a:not(#{$self}__footer-link-button) {
      @include underlined-link('light');
    }

    a.link-type_external {
      @include external-link-light-theme;
    }
  }

  &_in-fact-block {
    background: $white;
    line-height: convert-to-rem(26px);
    blockquote,
    & {
      font-family: $font-family;
    }

    ol,
    ul {
      margin: 28px 0 $block-margin;
      list-style: none;
      li {
        clear: both;
        margin: 0 0 convert-to-rem(26px);
        p {
          margin: 0;
        }
      }
    }

    ul li {
      &:before {
        content: '';
        @include bullet;
        margin: 12px;
        float: left;
        margin: 12px 10px 0 0;
      }
    }

    ol li {
      counter-increment: fact-ol-counter;

      &:before {
        content: counter(fact-ol-counter) '.';
        font-weight: bold;
        line-height: inherit;
        color: $dark-blue;
        text-align: left;
        margin-right: 5px;
      }
    }

    h2 {
      @include font-size(24px, 32px);
    }
    h3 {
      @include font-size(22px, 30px);
    }
    h4 {
      @include font-size(20px, 28px);
    }
  }

  &_in-not-found-page {
    line-height: convert-to-rem(26px);
    &.on-editing {
      background-color: $white;
    }

    &:not(#{$self}_in-fact-block){
      *:last-child{
        margin-bottom: 0;
      }

      ul {
        li {
        padding-left: 25px;
        &::before{
          top: 10px;
          left: 9px
        }
        }
      }
      ol {
        li {
          padding-left: 25px;
          &::before{
            left: -4px;
          }
        }
      }
    }
  }
}
