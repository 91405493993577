.cta-image-block {
    $self: &;
    width: 100%;

    &__container{
        position: relative;
        width: 100%;
        overflow: hidden;
        @include color-overlay($media-overlay-color, 2);
    }

    &__image{
        z-index: 1;
    }

    #{$self}__button{
        align-self: center;
        width: 100%;
        margin: 0 20px;
        max-width: 230px;
        padding: 16px;
        @include font-size(16px, 24px);

        @include breakpoint(small) {
            max-width: 270px;
        }

        @include breakpoint(medium) {
            padding: 28px 20px;
            max-width: 370px;
            @include font-size(24px, 32px);
        }

        &-container{
            padding:0 $spacing-medium;
            @include full-size;
            height: 100%;
            max-height: 100%;
            overflow: hidden;
            z-index: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.link-type_external:after,
        &.link-type_external:hover:after {
            @include breakpoint(medium) {
                width: 18px;
                height: 18px;
            }
        }
    }
}