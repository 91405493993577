.not-found-page {
    overflow: hidden;
    position: relative;

    @include breakpoint(small) {
        min-height: 100%;
    }


    &__container {
        @include container;
        display: flex;
        flex-direction: column;

        @include breakpoint("extra-small-max", "max") {
            padding-left: 0;
            padding-right: 0;
        }

        @include breakpoint(small) {
            flex-direction: row;
            justify-content: flex-end;
            padding: $spacing-xxxlarge $block-margin calc($spacing-xxxlarge * 2);
        }
    }

    &__text-container {
        background: $white;
        position: relative;
        z-index: 3;
        overflow: hidden;
        width: 100%;
        align-self: flex-start;
        padding: $block-margin $medium-size;

        @include breakpoint(small) {
            padding: $block-margin;
            max-width: 650px;
        }
    }

    &__error-heading {
        color: $red;
        @include font-size(40px, 48px);
        margin: 0 0 21px;

        @include breakpoint(small) {
            @include font-size(48px, 56px);
        }
    }

    &__heading {
        color: $dark-blue;
        @include font-size(32px, 40px);
        margin: 0 0 24px;
        @include breakpoint(small) {
            margin: 0 0 28px;
        }
    }

    &__button {
        @include breakpoint("extra-small-max", "max") {
            display: block;
            width: 100%;
        }
        margin: $block-margin 0 0;

        @include breakpoint(small) {
            padding-left: 43px;
            padding-right: 43px;
        }
    }

    &__image {
        z-index: 1;
        position: relative;
        width: 100%;
        @include color-overlay($media-overlay-color, 2);
        background-repeat: no-repeat;
        background-position: 10% 50%;
        background-size: cover;
        background-image: url("../../assets/images/404-page-bg-mobile.jpg");

        @include breakpoint("small-max", "max") {
            height: 572px;
        }

        @include breakpoint(small) {
            @include full-size;
            z-index: 1;
            height: 100%;
            background-position: 35% 50%;
            background-image: url("../../assets/images/404-page-bg.jpg");
        }

    }
}
