.main-menu {
	position: relative;
	display: flex;
	justify-content: space-between;

	&__list {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		display: none;
		list-style: none;
		background-color: $dark-blue;
		width: 100%;

		&_active {
			display: block;
		}

		@include breakpoint(large) {
			&, &_active {
				display: flex;
			}
			position: static;
			justify-content: space-between;
			width: auto;
			margin: 0;
		}
	}

	&__item {
		height: 100%;
		border-top: 2px solid $blue;
		text-align: center;

		&:last-child {
			border-bottom: 2px solid $blue;
		}

		@include breakpoint(large) {
			border-left: 2px solid $blue;
			border-top: none;

			&:last-child {
				border-right: 2px solid $blue;
				border-bottom: none;
			}
		}
	}

	&__link {
		display: block;
		height: 100%;
		padding: 8px $spacing-large;
		color: inherit;
		@include font-size(16px, 24px);
		text-decoration: none;
		outline: none;

		&:hover,
		&:focus-visible,
		&_active {
			background-color: $blue;
			color: inherit;
		}

		@include breakpoint(large) {
			display: inline-flex;
			align-items: center;
			@include font-size(18px, 26px);
			padding: 27px;
		}
	}

	&__toggle-button {
		display: inline-flex;
		align-items: center;
		margin-left: auto;
		background: none;
		padding: 0 1px;
		font-family: $font-family;
		@include font-size(16px, 20px);
		color: $white;
		border: none;
		cursor: pointer; 
        
        &:active {
            background-color: $blue;
        }

		&-text {
			margin-left: $spacing-small;
		}
		
		@include breakpoint(large) {
			display: none;
		}
	}
}
